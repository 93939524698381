import React from 'react'
import FullLogo from '../assets/xbank_logo.svg'

const AppBar = (): JSX.Element => {
    return (
        <header>
            <div className="flex justify-between items-center py-app-bar-y">
                <div className="flex items-center">
                    <FullLogo className="w-120px h-52px" />
                </div>
                <div className="grow"></div>
                <a
                    className="text-white border-2 font-bold px-mobile-app-button-x py-mobile-app-button-y nonmobile:px-app-button-x nonmobile:py-app-button-y rounded hover:text-primary transition hover:border-primary nonmobile:h-11 nonmobile:w-40 text-center text-14px"
                    href="https://app.xbank.finance"
                >
                    Enter App
                </a>
            </div>
        </header>
    )
}

export default AppBar
