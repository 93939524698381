import React, { ReactNode } from 'react'
import Twitter from '../assets/icons/twitter.svg'
import Telegram from '../assets/icons/telegram.svg'
import Discord from '../assets/icons/discord.svg'
import Medium from '../assets/icons/medium.svg'
import GitHub from '../assets/icons/github.svg'
import GitBook from '../assets/icons/gitbook.svg'
import PythLogo from '../assets/pyth.svg'

interface IMenu {
    href: string
    id: string
    icon: ReactNode
}

const AppFooter = (): JSX.Element => {
    const menus: Array<IMenu> = [
        {
            id: 'twitter',
            icon: <Twitter />,
            href: 'https://twitter.com/xBank_Finance',
        },
        {
            id: 'telegram',
            icon: <Telegram />,
            href: 'https://t.me/xBankFinance',
        },
        {
            id: 'discord',
            icon: <Discord />,
            href: 'https://discord.gg/Ywh9hJg6xv',
        },
        {
            id: 'medium',
            icon: <Medium />,
            href: 'http://medium.com/xbank-finance',
        },
        {
            id: 'github',
            icon: <GitHub />,
            href: 'https://github.com/xbank-lab',
        },
        {
            id: 'gitbook',
            icon: <GitBook />,
            href: 'https://docs.xbank.finance/xbank/',
        },
    ]
    return (
        <footer>
            <div className="flex justify-center nonmobile:justify-between nonmobile:items-end">
                <ul className="flex flex-row flex-grow-0 px-1 gap-6 items-center text-white text-2xl">
                    {menus.map((menu) => (
                        <li key={menu.id}>
                            <a
                                className="hover:text-primary transition"
                                href={menu.href}
                                rel="noreferrer"
                                target="_blank"
                            >
                                {menu.icon}
                            </a>
                        </li>
                    ))}
                </ul>
                <div className="hidden nonmobile:flex">
                    <PythLogo className="w-100px h-32px" />
                </div>
            </div>
        </footer>
    )
}

export default AppFooter
