import * as React from 'react'
import AppBar from '../components/AppBar'

import { Helmet } from 'react-helmet'
import AppFooter from '../components/AppFooter'

import zkSyncEraIcon from '../images/zk-sync-icon.png'
import PythLogo from '../assets/pyth.svg'

const IndexPage = (): JSX.Element => {
    return (
        <div className="bg-black bg-mobilebg bg-top bg-cover nonmobile:bg-left-top nonmobile:bg-fullbg bg-no-repeat">
            <Helmet>
                <meta charSet="utf-8" />
                <title>xBank</title>
                <meta property="og:url" content="https://www.xbankfinance.com/" />
                <meta name="description" content="Non-Custodial Liquidity Market Protocol on zkSync Era" />

                <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=5,minimum-scale=1" />
                <meta name="theme-color" content="#FFFFFF" />
            </Helmet>

            <div className="min-h-screen px-20px nonmobile:px-104px">
                <div className="min-h-screen flex flex-col">
                    <div className="flex-grow-0 flex-shrink-0">
                        <AppBar />
                    </div>
                    <div className="flex-grow flex flex-col nonmobile:gap-14 gap-4 nonmobile:justify-center justify-end nonmobile:py-56px text-white">
                        <div className="relative font-bold text-24px nonmobile:text-48px">
                            <div className="relative">
                                <div className="absolute nonmobile:hidden right-0 -top-10">
                                    <PythLogo className="w-14 h-8" />
                                </div>
                                <p>The Non-Custodial</p>
                            </div>
                            <p>Liquidity Market Protocol</p>
                            <p>
                                Built on <img className="inline h-5 nonmobile:h-7" src={zkSyncEraIcon} />
                                <span className="text-secondary"> zkSync Era</span>
                            </p>
                        </div>

                        <div className="text-14px nonmobile:text-15px">
                            <p>
                                xBank manages deposits for lenders and <br className="nonmobile:hidden" />
                                facilitates lending of the deposited asset
                            </p>
                            <p>
                                for borrowers while performing appropriate <br className="nonmobile:hidden" />
                                risk management to protect the lenders
                            </p>
                            <p>from risks of illiquidity and insolvency.</p>
                        </div>

                        <a
                            href="https://app.xbank.finance"
                            className="text-primary font-bold text-14px nonmobile:text-xs"
                        >
                            Try out our app now
                        </a>
                    </div>
                    <div className="flex-grow-0 flex-shrink-0 py-40px">
                        <AppFooter />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IndexPage
